@if(formControlItem){
  <div>
    @if(label){
      <label [for]="id" class="block text-sm font-medium leading-6 text-gray-900">{{label | translate}}</label>
    }
    <div class="relative mt-2 rounded-md shadow-sm">
      @if(iconPrefix){
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <i [class]="iconPrefix"></i>
        </div>
      }
      <input #currentElement [type]="type"
             [formControl]="formControlItem"
             [id]="id"
             [name]="id"
             autocomplete="off"
             [placeholder]="(placeholder || '')  | translate"
             [ngClass]="{
                  'ring-red-300 placeholder:text-red-300  text-red-900 focus:ring-red-500':formControlItem.invalid  && (formControlItem.dirty || formControlItem.touched),
                  'pl-10': iconPrefix,
                  'pl-4': !iconPrefix,
                  'pr-10': iconSufix,
                  'pr-4': !iconSufix,
                  'text-white': styling === TInputStyles.transparentSolid || TInputStyles.darkBig,
                  'border-white':  styling === TInputStyles.transparentSolid,
                  'border-black text-black': styling === TInputStyles.blackBorderInput,
                  'rounded-md border border-1 border-solid py-1 placeholder:text-secondary-hover text-18 bg-transparent': styling === TInputStyles.transparentSolid || styling === TInputStyles.blackBorderInput,
                  'rounded-lg border-0 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6': styling === TInputStyles.transparentBig
             }"
             class="block w-full {{customClasses}}" placeholder="you@example.com">
      @if(iconSufix){
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <i [class]="iconSufix"></i>
        </div>
      }
    </div>
    @if(formControlItem.invalid) {
      <app-validation-error [control]='formControlItem' class="text-red-500 text-xs italic"></app-validation-error>
    }

  </div>
} @else {
  <div>
    @if(label){
      <label [for]="id" class="block text-sm font-medium leading-6 text-gray-900">{{label | translate}}</label>
    }
    <div class="relative mt-2 rounded-md shadow-sm">
      <input #currentElement [type]="type"
             [id]="id"
             [value]="value"
             [name]="id"
             autocomplete="off"
             (input)="valueChange($event)"
             (blur)="onBlur()"
             [ngClass]="{
                  'pl-10': iconPrefix,
                  'pl-4': !iconPrefix,
                  'pr-10': iconSufix,
                  'text-white': styling === TInputStyles.transparentSolid || styling === TInputStyles.darkBig,
                  'pr-4': !iconSufix,
                  'border-white': styling === TInputStyles.transparentSolid,
                  'border-black text-black': styling === TInputStyles.blackBorderInput,
                  'rounded-md border border-1 border-solid py-1 placeholder:text-secondary-hover text-18 bg-transparent': styling === TInputStyles.transparentSolid || styling === TInputStyles.blackBorderInput,
                  'rounded-lg border-0 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6': styling === TInputStyles.transparentBig
             }"
             class="block w-full {{customClasses}}"
             [placeholder]="(placeholder || '')  | translate"
      >
    </div>
  </div>
}
