import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule,} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';
import {ValidationErrorComponent} from '@revolve-app/app/shared/forms/validation-errors/validation-error.component';
import {TooltipDirective} from '@revolve-app/app/core/utils/tooltip.directive';
import {TranslateModule} from "@ngx-translate/core";

export enum TInputStyles {
  transparentSolid = 'transparentSolid',
  blackBorderInput = 'blackBorderInput',
  darkBig = 'darkBig',
  transparentBig = 'transparentBig'
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
  imports: [FormsModule, ReactiveFormsModule, NgIf, ValidationErrorComponent, TooltipDirective, NgClass, TranslateModule],
})
export class InputComponent implements ControlValueAccessor {
  @Input() formControlItem?: any;
  @Input() placeholder?: string;
  @Input() iconSufix?: string;
  @Input() customClasses?: string;
  @Input() iconPrefix?: string;
  @Input() styling: TInputStyles = TInputStyles.transparentSolid;
  //@Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @Input({required: true}) type: string = 'text'; // default to text if not specified
  @Input() id?: string = String(Math.random()); // default to text if not specified
  @Input() label?: string;
  @Input() disabled: boolean = false;
  @ViewChild('currentElement') currentElement:any;
  constructor(private cdr: ChangeDetectorRef) {
  }


  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};


  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  valueChange($event: any) {
    this.writeValue($event.target.value)
    this.cdr.markForCheck();
  }

  // From ControlValueAccessor interface
  value: string = '';
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    if (this.type === 'number') {
      value = Number(value);
    }
    this.value = value;
    this.onChange(value);
    //this.ngModelChange.emit(value);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean = false): void {
    // Handle the disabled state if necessary
  }

  public focus(){
    this.currentElement.nativeElement.focus();
  }

  protected readonly TInputStyles = TInputStyles;
}
